import IMask from 'imask';
import { startsWith } from 'lodash';

let applyMask = function(selector, mask) {
    document.querySelectorAll(selector).forEach(
        element => {
            IMask(element, mask);
        }
    );

}

document.addEventListener('DOMContentLoaded', function() {

    // put in individual requirements here

    // postcode is 4 digits only
    applyMask('[data-mask=postcode]', {
        mask: '0000'
    });

    // phone number mask -- 15 digits max, with optional + at the start. use regex
    applyMask('[data-mask=phone]', {
        mask: /^[+64]?[0-9]{0,12}$/
    });

    // create a mask for name fields using the following rules:
    // - only A-Z and a-z, spaces, hyphens, apostrophes, and macrons for all vowels are allowed
    applyMask('[data-mask=name]', {
        mask: /^[A-Za-zĀĒĪŌŪāēīōū \-']*$/
    });

    // additional logic -- when name fields contain " and ", alert saying "Please enter information for one person only"
    document.querySelectorAll('[data-mask=name]').forEach(element => {
        element.addEventListener('input', function() {
            if (this.value.includes(' and ')) {
                alert('Please enter information for one person only');
                // remove everything after the " and ", including the " and "
                this.value = this.value.split(' and ')[0];
            }
        });
    });

});
