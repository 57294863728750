window.getProfileTab = () => {
    const hash = window.location.hash.substr(1); // Get hash without #
    console.log(hash);
    if (['profile', 'addresses', 'cards', 'notifications', 'password'].includes(hash)) {
        return hash;
    }
    return 'profile'; // Default tab if hash is invalid or not present
}

window.profileForm = () => {
    const user = window.user; // localized at PortalController.php
    return {
        formData: {
            firstname: user.firstname ?? '',
            lastname: user.lastname ?? '',
            email: user.email ?? '',
            mobilePhoneNumber: user.mobilePhoneNumber ?? '',
            homePhoneNumber: user.homePhoneNumber ?? '',
            workPhoneNumber: user.workPhoneNumber ?? '',
            dateOfBirth: user.dateOfBirth ?? '',
        },
        message: '',
        loading: false,
        buttonLabel: 'Save',

        submitData() {
            this.buttonLabel = 'Saving...';
            this.loading = true;
            this.message = '';

            const payload = {...this.formData}
            if (this.formData.dob) {
                // format to ISO8601
                payload.dateOfBirth = new Date(this.formData.dob).toISOString();
            }
            delete payload.dob;
            
            fetch('/portal/profile/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('input[name="CRAFT_CSRF_TOKEN"]').value,
                },
                body: JSON.stringify(payload)
            })
                .then(response => response.json())
                .then(data => {
                    this.message = 'Profile updated successfully!';
                })
                .catch(error => {
                    this.message = 'Oops! Something went wrong!';
                })
                .finally(() => {
                    this.loading = false;
                    this.buttonLabel = 'Save';
                });
        }
    };
}
