import axios from "axios";
import handleRemoveItemFromCart from "./removeItemFromCart";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

function addToCart(variantSku, amount = null) {
    console.log(variantSku, amount)
    // send post request to server to /create-one-off-donation
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('input[name="CRAFT_CSRF_TOKEN"]').value;
    const url = '/create-one-off-donation';
    const data = {
        sku: variantSku,
        amount: amount,
    };
    axios.post(url, data)
        .then((response) => {
            console.log('Response:', response);
            // redirect to cart page
            // window.location.href = '/cart';
            // replace the cart content with the new content #cart-form, not append.
            const cartForm = document.querySelector('#cart-form');
            // go up to the parent element and replace the innerHTML
            cartForm.parentElement.innerHTML = response.data;
            handleDonationOptions();
            handleRemoveItemFromCart();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

function handleDonationOptions() {
    const radioButtons = document.querySelectorAll('input[name="donation"]');
    console.log('Found radio buttons:', radioButtons);
    radioButtons.forEach((radio) => {
        radio.addEventListener('change', (event) => {
            const selectedRadio = event.target;
            const variantSku = selectedRadio.getAttribute('data-variant-sku');

            const inputField = document.querySelector('.donation-box__input');
            inputField.classList.add('hidden');
            if (variantSku) {
                console.log('Selected donation variant slug:', variantSku);
                // wee need to check first if variantSlug is donation-custom and if so, show the input field
                // if not we need to hide the input field and send a post request to the server to update the cart
                // with the selected variant and amount and then redirect to the cart page
                if (variantSku === 'donation-custom') {
                    inputField.classList.remove('hidden');

                    // on click on Apply button, we need to send the amount to the server continue this part
                    const applyButton = document.querySelector('.donation-box__input button#apply-button');
                    applyButton.addEventListener('click', (event) => {
                        // get amount from input field
                        const amountInput = document.querySelector('.donation-box__input input#donation-amount');
                        const amount = amountInput.value;
                        console.log('Selected donation amount:', amount);
                        // send request to server
                        addToCart(variantSku, amount)
                    });
                } else if (variantSku === 'remove') {
                    // send request to server
                    return false;
                } else {
                    // send request to server
                    addToCart(variantSku)
                }
            }
        });
    });
}

// manually remove from dropdown due to weird bug
function restrictDonationBirthYear() {
    let fieldId = "[data-fui-id=donations-dateOfBirth-year]";
    let field = document.querySelector(fieldId);
    // fully remove year options that are the current year or later
    if (field) {
        let currentYear = new Date().getFullYear();
        field.querySelectorAll('option').forEach((option) => {
            if (parseInt(option.value) >= currentYear) {
                option.remove();
            }
        });

        // reverse order of all options, except the first one
        let options = field.querySelectorAll('option');
        let firstOption = options[0];
        let optionsArray = Array.from(options).slice(1);
        optionsArray.reverse();
        field.innerHTML = '';
        field.appendChild(firstOption);
        optionsArray.forEach((option) => {
            field.appendChild(option);
        });
    }
}

// when donationType is changed, reset the donation amount to the first radio button for either fields[donationAmount] (if donationType is one-off) or fields[pledgeAmount] (if donationType is pledge)
function resetDonationAmountOnChange() {
    const donationType = document.querySelectorAll('[name="fields[donationType]"]');
    if (donationType) {
        donationType.forEach((field) => {
            field.addEventListener('click', (event) => {
                // click both first radio buttons
                document.querySelector('[name="fields[donationAmount]"]').click();
                document.querySelector('[name="fields[pledgeAmount]"]').click();
            });
        });
    }
}


function cleanPhoneNumber(input) {
    // Replace any character that is not a digit or + with an empty string
    // accept only up to 15 characters
    return input.replace(/[^\d+]/g, '').substring(0, 15);
}

function setupPhoneNumberCleanup() {
    document.querySelectorAll('[data-fui-id=donations-phoneNumber-number]').forEach((input) => {
        input.addEventListener('input', (event) => {
            input.value = cleanPhoneNumber(input.value);
        });
    });
}

function validateDateOfBirth() {
    let dobDayField = document.querySelector('[data-fui-id=donations-dateOfBirth-day]');
    let dobMonthField = document.querySelector('[data-fui-id=donations-dateOfBirth-month]');
    let dobYearField = document.querySelector('[data-fui-id=donations-dateOfBirth-year]');

    [dobDayField, dobMonthField, dobYearField].filter(f=>f).forEach((field) => {
        field.addEventListener('change', (event) => {
            cleanupDateOfBirth();
        });
    });
}

function cleanupDateOfBirth() {
    let dobDayField = document.querySelector('[data-fui-id=donations-dateOfBirth-day]');
    let dobMonthField = document.querySelector('[data-fui-id=donations-dateOfBirth-month]');
    let dobYearField = document.querySelector('[data-fui-id=donations-dateOfBirth-year]');

    // if any fields are blank, do nothing
    if (!dobDayField.value || !dobMonthField.value || !dobYearField.value) {
        return true;
    }

    // disable any days that are not valid for the selected dobMonthField
    let daysInMonth = new Date(dobYearField.value, dobMonthField.value, 0).getDate();
    dobDayField.querySelectorAll('option').forEach((option) => {
        if (parseInt(option.value) > daysInMonth) {
            // disable the option
            option.setAttribute('disabled', 'disabled');
        } else {
            // enable the option
            option.removeAttribute('disabled');
        }
    });

    // form the date object, make sure month and day are 2 digits
    let dateString = `${dobYearField.value}-${dobMonthField.value.padStart(2, '0')}-${dobDayField.value.padStart(2, '0')}`;
    let dateObject = dayjs(dateString, 'YYYY-MM-DD', true);
    if (!dateObject.isValid()) {
        console.log('Date is invalid');
        dobDayField.value = '';
    }
}

window.restrictDonationBirthYear = restrictDonationBirthYear;

document.addEventListener('DOMContentLoaded', function () {
    // find this <input type="hidden" name="handle" value="donations">
    const form = document.querySelector('input[name="handle"][value="donations"]')?.closest('form');
    console.log(form)
    if (form) {
        // Set the fields to read-only
        const currentUserField = form.querySelector('[name="fields[currentUserEmail]"]');

        if (currentUserField.value) {
            form.querySelectorAll('[name="fields[firstName]"], [name="fields[lastName]"], [name="fields[emailAddress]"]').forEach(e => e.setAttribute('readonly', 'readonly'));
            form.querySelectorAll('[data-field-handle=dateOfBirth] select').forEach(e => e.setAttribute('disabled', 'disabled'));
        }
    }

    restrictDonationBirthYear();
    validateDateOfBirth();
    setupPhoneNumberCleanup();
});

document.addEventListener('DOMContentLoaded', (event) => {
    handleDonationOptions();
    resetDonationAmountOnChange();
});

export default handleDonationOptions;
