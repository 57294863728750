import algoliasearch from "algoliasearch";

let searchClient;
let index;

let query = '';
let hits = [];
let nbHits = 0;
const inputSearch = document.querySelectorAll('input#search');
const closeSearch = document.querySelectorAll('.search-bar .search-close');
let searchResults = document.querySelector('div#searchResults');

searchClient = algoliasearch(
    window.globalSearchParams?.sid,
    window.globalSearchParams?.skey
);

index = searchClient.initIndex(window.globalSearchParams?.senv);

async function search(query, resultsContainer) {
  const results = await index.search(query, {
    hitsPerPage: 6,
  });
  hits = results.hits;
  nbHits = results.nbHits;
  resultsContainer.innerHTML = '';
  if(hits.length > 0) {
    hits.forEach((hit) => {
      resultsContainer.innerHTML += `
          <div class="search-results__item">
            <a href="${hit.uri}">
                <div class="title">${hit.title}</div>
                <div class="description">${hit.shortDescription}</div>
            </a>
          </div>
        </div>
      `;
    });
    resultsContainer.innerHTML += `<a class="view-all-search" href="/search-results/?s=${query}">View All Results (${nbHits})</a>`;

    if(resultsContainer.classList.contains('results-showed')) {
      resultsContainer.querySelectorAll('.search-results__item a').forEach((link) => {
        link.addEventListener('click', () => {
          closeSearchContainer();
        });
      });
    }

  } else {
    resultsContainer.innerHTML += `<div class="search-results__item no-results">No results found for ${query}</div>`;
  }
}
inputSearch.forEach((input) => {
  input.addEventListener('input', async function (e) {
    if(e.target.value !== '') {
      search(e.target.value, input.nextElementSibling);
      input.nextElementSibling.classList.add('results-showed');
    } else {
      input.nextElementSibling.innerHTML = '';
      input.nextElementSibling.classList.remove('results-showed');
    }
  });
});
// inputSearch.addEventListener('input', async function (e) {
//   if(e.target.value !== '') {
//     search(e.target.value);
//     searchResults.classList.add('results-showed');
//   } else {
//     searchResults.innerHTML = '';
//     searchResults.classList.remove('results-showed');
//   }
// });

if(closeSearch.length > 0) {
  closeSearch.forEach((close) => {
    close.addEventListener('click', () => {
      const inputContainer = close.previousElementSibling;
      const input = inputContainer.querySelector('input');
      closeSearchContainer(input, inputContainer.querySelector('.search-result-container'));
    });
  });
}

function closeSearchContainer(input, searchResults) {
  searchResults.innerHTML = '';
  input.value = '';
}

//Focus on input mobile
const mobileSearch = document.querySelector('.mobile-navigation-wrapper__container');
if(mobileSearch) {
  mobileSearch.querySelector('.search').addEventListener('click', function() {
    const searchInput = mobileSearch.querySelector('#search');
    searchInput.focus();
    searchInput.setAttribute('readonly', 'readonly');
    setTimeout(function() {
      searchInput.removeAttribute('readonly');
      searchInput.focus();
    }, 300);
  });
}
