import axios from "axios";
import "./donations";
import handleDonationOptions from "./donations";

function removeItem(itemId) {
    // send post request to server to /create-one-off-donation
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('input[name="CRAFT_CSRF_TOKEN"]').value;
    const url = '/cart/remove-item';
    const data = {
        itemId: itemId
    };
    axios.post(url, data)
        .then((response) => {
            console.log('Response:', response);
            // redirect to cart page
            // window.location.href = '/cart';
            // replace the cart content with the new content #cart-form, not append.
            const cartContainer = document.querySelector('#cart-container');
            // go up to the parent element and replace the innerHTML
            cartContainer.parentElement.innerHTML = response.data;
            handleRemoveItemFromCart();
            handleDonationOptions();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

function handleRemoveItemFromCart() {
    const removeItemButtons = document.querySelectorAll('.remove-item');
    console.log('Remove Item Buttons:', removeItemButtons);
    // ignore what was actually clicked, get the data-item-id from all .remove-item divs.
    removeItemButtons.forEach((removeItemButton) => {
        removeItemButton.addEventListener('click', (event) => {
            // get the data-item-id from the button that was clicked.
            const itemId = removeItemButton.getAttribute('data-item-id');
            removeItem(itemId)
        });
    });
}

document.addEventListener('DOMContentLoaded', (event) => {
    handleRemoveItemFromCart();
});

export default handleRemoveItemFromCart;
