import autoComplete from "@tarekraafat/autocomplete.js";

export default () => {

  const addrToken = window.FormieNzPostAddressSettings ?? document.querySelector('[data-field-type="address"]')?.dataset.addr;

    if (!addrToken) {
        return;
    }

  const filterAddressType = (sourceDesc) => {
    return sourceDesc.includes('Postal') || sourceDesc.includes('Box') || sourceDesc.includes('Bag');
  };

  const addressInput = document.querySelectorAll('[data-field-type="address"]');
  addressInput.forEach((inputContainer) => {
    // Find input field inside the inputContainer
    const searchInput = inputContainer.querySelector('input[type="search"]');
    if(searchInput) {
      searchInput.setAttribute('id', 'autoComplete');

      const autoCompleteJS = new autoComplete({
        data: {
          src: async (query) => {
            try {
              document
                  .getElementById("autoComplete")
                  .setAttribute("placeholder", "Searching...");
              const source = await fetch(`https://api.nzpost.co.nz/addresschecker/1.0/suggest?q=${query}`,{
                headers: {
                  'Authorization': `Bearer ${addrToken}`,
                  'Accept': 'application/json'
                }
              });
              const data = await source.json();
              document
                  .getElementById("autoComplete")
                  .setAttribute("placeholder", autoCompleteJS.placeHolder);
              return data.addresses.filter(address => filterAddressType(address.SourceDesc));
            } catch (error) {
              return error;
            }
          },
          keys: ["FullAddress"],
        },
        debounce: 500,
        placeHolder: "Search for an address",
        resultsList: {
          element: (list, data) => {
            if (data.results.length === 0) {
              const info = document.createElement("p");
              info.innerHTML = `Found <strong>${data.matches.length}</strong> matching results for <strong>"${data.query}"</strong>`;
              list.prepend(info);
            }
          },
          noResults: true,
          maxResults: 15,
          tabSelect: true
        },
        resultItem: {
          element: (item, data) => {
            // Modify Results Item Style
            item.style = "display: flex; justify-content: space-between;";
            // Modify Results Item Content
            item.innerHTML = `
              <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                ${data.match}
              </span>`;
          },
          highlight: 'text-secondary selection:bg-white',
        },
        events: {
          input: {
            focus: () => {
              if (autoCompleteJS.input.value.length) autoCompleteJS.start();
            }
          }
        }
      });

      const autoCompleteInput = inputContainer.querySelector('input[name="fields[address][autoComplete]"], #autoComplete');
      const address1 = inputContainer.querySelector('input[name="fields[address][address1]"], input[data-addr=streetAddress]');
        const suburb = inputContainer.querySelector('input[name="fields[address][address3]"], input[data-addr=suburb]');
        const cityTown = inputContainer.querySelector('input[name="fields[address][city]"], input[data-addr=city]');
        const postCode = inputContainer.querySelector('input[name="fields[address][zip]"], input[data-addr=postcode]');
        const manualAddress = document.querySelector('input[name="fields[manualAddress][]"], #manualAddress');
        const dpid = document.querySelector('input[name="fields[dpid]"], input[data-addr=dpid]');


      //Move manual address checkbox below postal address
      if (document.querySelector('input[name="fields[manualAddress][]"]')) {
        const manualAddressParent = manualAddress.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
        const autoCompleteInputParent = autoCompleteInput.parentNode.parentNode.parentNode.parentNode.parentNode;
        autoCompleteInputParent.parentNode.insertBefore(manualAddressParent, autoCompleteInputParent.nextSibling);
      }

      toggleAddressFields([address1, suburb, cityTown, postCode], true);

      manualAddress.addEventListener('change', function() {
        if(manualAddress.checked) {
            toggleAddressFields([address1, suburb, cityTown, postCode], false);
            dpid.value = '';
            autoCompleteJS.unInit();
        } else {
            toggleAddressFields([address1, suburb, cityTown, postCode], true);
            autoCompleteJS.init();
            propagateFields(window.latestAddressData, window.latestDpid);
        }
      });

      autoCompleteJS.input.addEventListener("selection", async function (event) {
        const feedback = event.detail;
        autoCompleteJS.input.blur();
        // Prepare User's Selected Value
        const selection = feedback.selection.value[feedback.selection.key];
        // Render selected choice to selection div
        //document.querySelector(".selection").innerHTML = selection;
        // Replace Input value with the selected value
        autoCompleteJS.input.value = selection;
        // Console log autoComplete data feedback
        // console.log(feedback);

        const getDpid = feedback.selection.value.DPID;

        const details = await fetch(`https://api.nzpost.co.nz/addresschecker/1.0/details?dpid=${getDpid}`,{
          headers: {
            'Authorization': `Bearer ${addrToken}`,
            'Accept': 'application/json'
          }
        });
        const data = await details.json();
        if (data.success) {
            propagateFields(data, getDpid);
        } else {
          alert('Sorry, there was an error automatically filling out your selected address details. Please fill out the address details manually.');
        }


      });
    }
  });
}

function propagateFields(data, dpid) {
    window.latestAddressData = data;
    window.latestDpid = dpid;
    const inputContainer = document.querySelector('[data-field-type="address"]');
    const address1 = inputContainer.querySelector('input[name="fields[address][address1]"], input[data-addr=streetAddress]');
    const suburb = inputContainer.querySelector('input[name="fields[address][address3]"], input[data-addr=suburb]');
    const cityTown = inputContainer.querySelector('input[name="fields[address][city]"], input[data-addr=city]');
    const postCode = inputContainer.querySelector('input[name="fields[address][zip]"], input[data-addr=postcode]');
    const dpidField = document.querySelector('input[name="fields[dpid]"], input[data-addr=dpid]');

    if (!data || !data.details || data.details.length === 0) {
        return;
    }
    
    let selectedAddress = data.details.find((detail) => detail.SourceDesc === "Postal") ?? data.details[0];

    address1.value = selectedAddress.AddressLine1;
    suburb.value = selectedAddress.Suburb ?? selectedAddress.Lobby ?? selectedAddress.RuralDelivery;
    dpidField.value = dpid;
    cityTown.value = selectedAddress.CityTown ?? selectedAddress.MailTown;
    postCode.value = selectedAddress.Postcode;
    console.log(dpid);
    
}

function toggleAddressFields(fieldsArray, state = true) {
    fieldsArray.forEach((field) => {
        state ? field?.setAttribute('readonly', state) : field?.removeAttribute('readonly');
    });
}
