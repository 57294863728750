import axios from 'axios';
const regionDropdown = document.querySelector('select[data-dynamic="region"]');
const unitDropdown = document.querySelector('select[data-dynamic="unit"]');

const lookupUnit = function (postcode) {
    const unitField = document.querySelector('input[name="fields[yourAssignedCoastguardUnit]"]');
    axios.get('/lookup/membership-unit?postcode=' + postcode).then(function (response) {
        let data = response.data;
        // populate input[name=fields[yourAssignedCoastguardUnit]] with data.name
        if (unitField) {
            unitField.value = data.name;
        }
        // populate input[name=fields[unitGuid]] with data.guid
        const unitGuidField = document.querySelector('input[name="fields[unitGuid]"]');
        if (unitGuidField) {
            unitGuidField.value = data.guid;
        }
    }).catch(function (error) {
        unitField.value = '';
    });
}

if (regionDropdown && unitDropdown) {
    // Store the original unit options
    const originalUnitOptions = Array.from(unitDropdown.options);
    const defaultOption = unitDropdown.options[0].cloneNode(true);

    regionDropdown.addEventListener('change', function () {
        const selectedRegion = regionDropdown.value;
        // Clear current unit options
        unitDropdown.innerHTML = '';
        unitDropdown.appendChild(defaultOption);

        // Filter and add relevant unit options
        originalUnitOptions.forEach(option => {
            const [unitId, regionId] = option.value.split('|');
            if (regionId === selectedRegion) {
                unitDropdown.appendChild(option);
            }
        });

        // If no units match, add a default option
        if (unitDropdown.options.length < 1) {
            const defaultOption = document.createElement('option');
            defaultOption.text = 'No units available';
            defaultOption.disabled = true;
            defaultOption.selected = true;
            unitDropdown.appendChild(defaultOption);
        }
    });
    // Trigger change event on page load to initialize the dropdowns correctly
    // regionDropdown.dispatchEvent(new Event('change'));
}
const postcodeField = document.querySelector('input[name="fields[user_postcode]"]');
if (postcodeField) {
    postcodeField.addEventListener('input', function () {
        const postcode = postcodeField.value;
        const postcodeRegex = /^[0-9]{4}$/;
        if (!postcodeRegex.test(postcode)) {
            postcodeField.setCustomValidity('Postcode must be 4 digits');
        } else {
            postcodeField.setCustomValidity('');
            lookupUnit(postcode);
        }
    });
}

// if postcodeField is pre-filled, do a one-off lookup
if (postcodeField && postcodeField.value) {
    lookupUnit(postcodeField.value);
}
