import '../css/app.pcss';
import Alpine from "alpinejs";
import 'lazysizes';

import './slider';
import './fancybox';
import './membership';
import './alpineForms';
import './donations';
import './search';
import './map';
import './formMasking';
import './removeItemFromCart';
import NzPostAddress from '../../modules/nzpost/assets/nzpost-address';
import { UAParser } from 'ua-parser-js';


NzPostAddress();
//Init AlpineJS
Alpine.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

// get user agent
window.userAgentData = UAParser(navigator.userAgent);
